import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './CardListModule.module.scss';
import isEmpty from '../../utils/isEmpty';
import Button from '../Button';
import CardNews from '../CardNews';
import Card from '../Card';
import i18n from '../../i18n';

const CardListModule = ({ title, preamble, type, button, items }) => {
    const Tag = type === 'rental' ? Card : CardNews;

    const CardListModuleInnerClass = classNames(
        styles['CardListModule__Inner'],
        {
            [styles['CardListModule__Inner--Rental']]: type === 'rental',
        }
    );

    const buttonText =
        button.text || i18n.t('newsListModule.moreNewsButtonLabel');

    return (
        <div className={styles['CardListModule']}>
            <h2 className={styles['CardListModule__Title']}>{title}</h2>
            {preamble && (
                <div className={styles['CardListModule__Text']}>{preamble}</div>
            )}

            <div className={styles['CardListModule__Container']}>
                <div className={CardListModuleInnerClass}>
                    {!isEmpty(items) &&
                        items.map((item, idx) => {
                            return (
                                <Tag
                                    modifier={
                                        type === 'rental' ? 'Rental' : null
                                    }
                                    key={idx}
                                    {...item}
                                />
                            );
                        })}
                </div>

                {!isEmpty(button) && (
                    <div className="NewsListModule__Button">
                        <Button {...button} text={buttonText} />
                    </div>
                )}
            </div>
        </div>
    );
};

CardListModule.propTypes = {
    title: PropTypes.string.isRequired,
    preamble: PropTypes.string,
    button: PropTypes.object,
    items: PropTypes.array,
};

CardListModule.defaultProps = {
    title: '',
    preamble: '',
    button: {},
    items: [],
};

export default CardListModule;
